import React from "react"

const ProfileField = ({ title, content }) => {
  if (Array.isArray(content))
    return (
      <div>
        <p>{title}</p>
        <ul>
          {content.length ? (
            content.map(s => <li key={s}>{s}</li>)
          ) : (
            <li>No {title} specified</li>
          )}
        </ul>
      </div>
    )
  if (!content) return null
  return (
    <li className="[ faq-item ]">
      <h3 className="[ faq-item__title ]">{title}</h3>
      <p className="[ faq-item__description ]">{content}</p>
    </li>
  )
}

export default ProfileField
