import React, { useContext, useEffect } from "react"
import { navigate, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"

// Utility
import { Pods } from "../config/db"
import useQueryParams from "../hooks/useQueryParams"
import withWindow from "../helpers/withWindow"
import { AuthContext } from "../context/AuthContext"

// Components
import KnockOut from "../components/layout/KnockOut"
import { Field } from "../components/profile/"
import {
  displayPodCost,
  displayPodVenue,
  displayCultureSection,
} from "../helpers/pods"

const PodProfile = ({ data }) => {
  const { culture } = data.podAttributes

  const params = useQueryParams()
  const id = params.get("id")
  const { hasPod } = useContext(AuthContext)
  const {
    data: pod,
    isLoading,
    isFetching,
    refetch,
  } = useQuery("Profile - fetchProfile", () => Pods.fetchById(id))

  const isPodOwner = hasPod?.id === pod?.id

  useEffect(() => {
    refetch()
  }, [refetch, id])

  const deletePod = async () => {
    const message =
      "Are you sure you want to delete your pod? This action can't be undone"
    if (confirm(message)) {
      Pods.delete(pod.id)
      navigate(`/inform?mode=delete`)
    }
  }

  if (!id || (!isLoading && !pod)) {
    navigate("/404")
    return null
  }

  return (
    <>
      <Helmet>
        <title>{pod ? pod.podName : "pod"} - Profile</title>
      </Helmet>
      {isLoading || isFetching ? (
        <KnockOut />
      ) : (
        <article className="[ wrapper flow ] [ pod-profile ]">
          <div className="[ shadow-box ] ">
            <header className="[ mirror ] [ pod-header ]">
              <div className="pod-overview" data-status={pod.status}>
                <h2 className="pod-title">{pod.podName}</h2>
                {!isPodOwner && (
                  <button
                    aria-label="Report this pod"
                    onClick={() => navigate(`/report?id=${pod?.id}`)}
                    className="[ button ] [ small ] [ report ]"
                  >
                    🏳️
                    <br />
                    Report
                  </button>
                )}
                <p className="pod-info">
                  <span>{displayPodVenue(pod)}</span>
                  {pod.grade && <span> - {pod.grade}</span>}
                </p>
                <section>
                  <h3 className="visually-hidden">Pod information</h3>
                  <p className="pod-meta">
                    <span>{pod.location}</span>
                    <span>{pod.curriculum}</span>
                    <span>{pod.instructor}</span>
                    <span>{pod.podSize} Openings</span>
                  </p>
                </section>
                <span className="cost">{displayPodCost(pod)}</span>
              </div>
              {isPodOwner ? (
                <div className="[ flow-hrz flow-space-300 ][ actions ]">
                  <Link to="/edit">
                    <button className="button green">Edit</button>
                  </Link>
                  <button className="button red" onClick={deletePod}>
                    Delete
                  </button>
                </div>
              ) : (
                <div className="[ flow-hrz flow-space-300 ][ actions ]"></div>
              )}
            </header>

            <section className="[ auto-grid ] [ pod-details ]">
              <Field
                title="Safety requirements"
                content={pod.safety ? pod.safety : []}
              />
              <Field
                title="Preferences"
                content={pod.preferences ? pod.preferences : []}
              />
              <Field
                title="Technology"
                content={pod.technology ? pod.technology : []}
              />
            </section>
          </div>
          {displayCultureSection(pod, culture) && (
            <section className="[ shadow-box ]">
              <ul className=" [ flow ] ">
                {culture.map(({ value, profileTitle }) => (
                  <Field
                    key={value}
                    title={profileTitle}
                    content={pod[value]}
                  />
                ))}
              </ul>
            </section>
          )}
          {!isPodOwner &&
            (pod.status === "Open" ? (
              <button
                className="[ button ] [ green ]"
                type="submit"
                onClick={() => navigate(`/application?id=${pod?.id}`)}
              >
                Apply to join this pod
              </button>
            ) : (
              <button disabled className="[ button ]">
                This pod is closed and is not accepting applications at this
                time
              </button>
            ))}
        </article>
      )}
    </>
  )
}

export default withWindow(PodProfile)

export const query = graphql`
  {
    podAttributes {
      culture {
        profileTitle
        value
      }
    }
  }
`
